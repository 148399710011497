import { render, staticRenderFns } from "./role.vue?vue&type=template&id=fff5237e&scoped=true&"
import script from "./role.vue?vue&type=script&lang=js&"
export * from "./role.vue?vue&type=script&lang=js&"
import style0 from "./role.vue?vue&type=style&index=0&id=fff5237e&scoped=true&lang=css&"
import style1 from "./role.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fff5237e",
  null
  
)

export default component.exports